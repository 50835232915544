import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Album from 'shared/models/Album'
import { useFormik } from 'formik'
import getAlbumValidationSchema from './AlbumValidationSchema'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material'
import { Currency } from '../../../../../shared/util/Currency'
import InputInformation from '../../../../../components/UI/InputInformation'
import storesContext from '../../../../../providers/storesContext'
import { Countries } from '../../../../../shared/util/countries'
import { Country } from '../../../../../shared/models/Country'
import { Location } from '../../../../../shared/models/Location'
import RequestActivityModal from '../../components/RequestActivityModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCircleInfo,
  faEye,
  faEyeSlash,
  faLock,
} from '@fortawesome/free-solid-svg-icons'
import RequestLocationModal from '../../components/NewLocationRequestModals/RequestLocationModal'
import CreateAlbumInterface from '../../../../../services/Interfaces/Album/CreateAlbum.interface'
import Breadcrumb from '../../../../../components/UI/Breadcrumb'
import Button from '../../../../../components/UI/Button'
import { isNil } from 'lodash'
import CreateOrUpdateAlbumStore from '../../CreateOrUpdateAlbumStore'
import { Toast } from '../../../../../shared/util/toast'
import EditAlbumInterface from '../../../../../services/Interfaces/Album/EditAlbum.interface'
import moment from 'moment'
import CustomCurrencyOptionComponent from '../../components/AutocompleteCustomCurrencyOption'
import Checkbox from 'components/UI/Checkbox'
import { getFeatureFlagValue } from 'shared/utility'
import { FeatureFlags } from 'config/constants/featureFlags'

type AlbumFormProps = {
  albumToEdit?: Album
  handleFinish: (albumToEdit: Album) => void
  store: CreateOrUpdateAlbumStore
}

const AlbumForm = ({ albumToEdit, handleFinish, store }: AlbumFormProps) => {
  const { t } = useTranslation()
  const [showNewActivityModal, setShowNewActivityModal] = useState(false)
  const [showNewLocationModal, setShowNewLocationModal] = useState(false)
  const { activityStore, locationStore, featureFlagsStore } = useContext(storesContext)!

  const [countryCode, setCountryCode] = useState<string>(locationStore.userLocation.country.code)
  const [availableLocations, setAvailableLocations] = useState<Location[]>([])
  const [isPrivateAlbum, setIsPrivateAlbum] = useState<boolean>(
    !isNil(albumToEdit?.password) ? true : false
  )
  const [showPassword, setShowPassword] = useState(false)

  const isNewAlbum = isNil(albumToEdit)

  const burstPurchaseFeatureFlag = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.BUY_BURSTS_PACKAGE,
    false
  )

  useEffect(() => {
    if (!isNil(albumToEdit)) {
      locationStore.fetchLocations(albumToEdit?.location.countryCode).then((result) => {
        setAvailableLocations(result)
        setCountryCode(albumToEdit.location.countryCode)
      })
    } else {
      setAvailableLocations(locationStore.locations.get(locationStore.userLocation.country.code)!)
      setCountryCode(locationStore.userLocation.country.code)
    }
  }, [])

  const initialValues: CreateAlbumInterface | EditAlbumInterface = !isNewAlbum
    ? {
        id: albumToEdit?.id || '',
        description: albumToEdit?.description || '',
        currency: albumToEdit?.currency || '',
        defaultImagePrice: albumToEdit?.defaultImagePrice || 0,
        defaultPackagePrice: albumToEdit?.defaultPackagePrice || undefined,
        burstPrice: albumToEdit?.burstPrice || undefined,
        takenDate: albumToEdit?.takenDate || new Date(new Date().setHours(0, 0, 0, 0)),
        activityId: albumToEdit?.activity?.id || '',
        locationId: albumToEdit?.location?.id || '',
        password: albumToEdit?.password || undefined,
      }
    : {
        description: '',
        currency: '',
        defaultImagePrice: 0,
        defaultPackagePrice: undefined,
        burstPrice: undefined,
        takenDate: new Date(new Date().setHours(0, 0, 0, 0)),
        activityId: '',
        locationId: '',
        password: undefined,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: getAlbumValidationSchema(),
    onSubmit: (values) => {
      if (isNewAlbum) {
        if (typeof values.defaultPackagePrice === 'string') {
          values.defaultPackagePrice = undefined
        }
        if (typeof values.burstPrice === 'string') {
          values.burstPrice = undefined
        }
        store.createAlbum(values).then((newAlbum) => {
          if (newAlbum) {
            handleFinish(newAlbum)
          } else {
            Toast(t('Error creating album', 'error'))
          }
        })
      } else {
        const editValues = values as EditAlbumInterface
        store.updateAlbum(editValues).then((updatedAlbum) => {
          if (updatedAlbum) {
            handleFinish(updatedAlbum)
          } else {
            Toast(t('Error updating album', 'error'))
          }
        })
      }
    },
  })

  const handleChangePrivateAlbum = (value: boolean) => {
    if (!value) {
      formik.setFieldValue('password', undefined)
    }
    setIsPrivateAlbum(value)
  }

  const handleChangeCurrency = (newValue: Currency | null) => {
    formik.setFieldValue('currency', newValue ? newValue : '')
    if (newValue && newValue.toLowerCase() !== '') {
      featureFlagsStore.setMinimumPhotographPrice(newValue)
    }
  }

  const handleChangeCountry = (e: React.ChangeEvent<any>) => {
    setCountryCode(e.target.value)
    locationStore.fetchLocations(e.target.value).then((result) => {
      setAvailableLocations(result)
    })
  }

  const currencies = Object.values(Currency)

  const countryOptions = Countries.map((country: Country) => {
    const flagClass = `ml-2 mx-2 fi fi-${country.code.toLowerCase()}`
    return (
      <MenuItem key={country.code} value={country.code}>
        <span className={flagClass} />
        {country.name.toUpperCase()}
      </MenuItem>
    )
  })

  const activityOptions = activityStore.activities
    .map((activity) => {
      return { value: activity.id, label: t(activity.name).toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const locationOptions = availableLocations
    .map((location) => {
      return { value: location.id, label: location.spotName.toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  return (
    <div className="py-1 sm:pl-2 sm:pr-2">
      {isNewAlbum ? (
        <>
          <div className="flex justify-center w-full my-2 sm:hidden">
            <Breadcrumb completedSteps={1} totalSteps={5} />
          </div>
          <div className="mb-1 text-xl font-extrabold text-gray-800">
            {t('New independent album')}
          </div>
        </>
      ) : (
        <div className="mb-2 text-xl font-extrabold text-gray-800">{t('Edit album')}</div>
      )}
      <div className="flex flex-col py-2 overflow-y-auto">
        <div className="flex flex-col w-full gap-2 md:flex-row">
          <div className="mt-3 md:mt-0 md:w-1/5">
            <TextField
              fullWidth
              id="takenDate"
              name="takenDate"
              label={t('Date')}
              required
              InputLabelProps={{ shrink: true }}
              type="Date"
              value={moment.utc(formik.values.takenDate).format('YYYY-MM-DD')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.takenDate && Boolean(formik.errors.takenDate)}
              helperText={
                formik.touched.takenDate
                  ? typeof formik.errors.takenDate === 'string'
                    ? formik.errors.takenDate
                    : ''
                  : ''
              }
            />
          </div>
          <div className="flex flex-col w-full mt-3 md:mt-0 md:w-4/5">
            <TextField
              fullWidth
              variant="outlined"
              id="description"
              name="description"
              label={t('Description')}
              required
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
            <InputInformation
              informationText={
                'Text visible for athletes: add here album details (specific hour, location or other)'
              }
            />
          </div>
        </div>

        <div className="flex flex-col w-full gap-2 md:flex-row md:mt-2">
          <div className="flex flex-col w-full mt-2 md:w-1/4">
            <Autocomplete
              options={currencies}
              value={currencies.find((currency) => currency === formik.values.currency) || null}
              onChange={(event, newValue) => {
                handleChangeCurrency(newValue)
              }}
              getOptionLabel={(option) => (option ? option.toUpperCase() : '')}
              renderOption={(props, option) =>
                option ? <CustomCurrencyOptionComponent option={option} {...props} /> : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="currency"
                  id="currency"
                  label={t('Currency')}
                  required
                  error={formik.touched.currency && Boolean(formik.errors.currency)}
                  helperText={formik.touched.currency && formik.errors.currency}
                />
              )}
            />
            <InputInformation informationText={'Album will be sold in the Currency you select.'} />
          </div>
          <div className="flex flex-col w-full mt-2 md:w-1/4">
            <TextField
              id="defaultImagePrice"
              name="defaultImagePrice"
              label={t('Default Image Price')}
              required
              type="number"
              value={formik.values.defaultImagePrice}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.defaultImagePrice && Boolean(formik.errors.defaultImagePrice)}
              helperText={formik.touched.defaultImagePrice && formik.errors.defaultImagePrice}
            />
            <InputInformation
              informationText={
                'In case you want to set different prices on each photo, you can edit later from My Albums'
              }
            />
          </div>
          <div className="flex flex-col w-full mt-2 md:w-1/4">
            <TextField
              id="defaultPackagePrice"
              name="defaultPackagePrice"
              label={t('Default Package Price')}
              type="number"
              value={formik.values.defaultPackagePrice}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.defaultPackagePrice && Boolean(formik.errors.defaultPackagePrice)
              }
              helperText={formik.touched.defaultPackagePrice && formik.errors.defaultPackagePrice}
            />
            <InputInformation
              informationText={
                'All the photos of a person (detected by facial recognition or by runner number) make up a package.'
              }
            />
          </div>
          {burstPurchaseFeatureFlag && (
            <div className="flex flex-col w-full mt-2 md:w-1/4">
              <TextField
                id="burstPrice"
                name="burstPrice"
                label={t('Burst Price')}
                type="number"
                value={formik.values.burstPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.burstPrice && Boolean(formik.errors.burstPrice)}
                helperText={formik.touched.burstPrice && formik.errors.burstPrice}
              />
              <InputInformation
                informationText={
                  'Photos from a 3 second inverval are considered a burst (detected by the camera metadata).'
                }
              />
            </div>
          )}
        </div>
        <div className="flex flex-col w-full mt-3">
          <Autocomplete
            options={activityOptions}
            value={
              activityOptions.find((activity) => activity.value === formik.values.activityId) ||
              null
            }
            getOptionLabel={(option) => option.label}
            onChange={(activity, newValue) => {
              formik.setFieldValue('activityId', newValue ? newValue.value : '')
            }}
            loading={activityStore.isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                name="activityId"
                id="activityId"
                label={t('Sport')}
                required
                error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                helperText={formik.touched.activityId && formik.errors.activityId}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {activityStore.isLoading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <div className="flex sm:items-center gap-1 mt-2 mb-1.5">
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="text-sm ml-0.5 text-lumepic-light_black opacity-80"
            />
            <span className="text-lumepic-black text-xs ml-0.5 sm:ml-1">
              {t('If you don’t find the sport, ')}
              <span
                onClick={() => setShowNewActivityModal(true)}
                className="border-b border-black cursor-pointer"
              >
                {t('click here')}
              </span>
              {t(' to request the new sport')}.
            </span>
          </div>
        </div>

        <div className="flex flex-col w-full gap-2 sm:flex-row sm:mt-4">
          <div className="flex flex-col w-full mt-3 sm:mt-0 sm:w-1/4 md:w-1/5">
            <TextField
              variant="outlined"
              name="countryCode"
              id="countryCode"
              select
              label={t('Country')}
              required
              value={countryCode}
              onChange={handleChangeCountry}
            >
              {locationStore.isLoadingUserLocation ? (
                <MenuItem value={''}>{t('Loading') + '...'}</MenuItem>
              ) : (
                countryOptions
              )}
            </TextField>
          </div>
          <div className="flex flex-col w-full mt-3 sm:mt-0 sm:w-3/4 md:w-4/5">
            <Autocomplete
              options={locationOptions}
              value={
                locationOptions.find((location) => location.value === formik.values.locationId) ||
                null
              }
              getOptionLabel={(option) => option.label}
              onChange={(location, newValue) => {
                formik.setFieldValue('locationId', newValue ? newValue.value : '')
              }}
              loading={locationStore.isLoadingLocations}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  name="locationId"
                  id="locationId"
                  label={t('Location')}
                  required
                  error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                  helperText={formik.touched.locationId && formik.errors.locationId}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {locationStore.isLoadingLocations ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <div className="flex sm:items-center gap-1 mt-2 mb-1.5">
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="text-sm text-lumepic-light_black ml-0.5 opacity-80"
              />
              <span className="text-lumepic-black text-xs ml-0.5 sm:ml-1">
                {t('If you don’t find the spot, ')}
                <span
                  onClick={() => setShowNewLocationModal(true)}
                  className="border-b border-black cursor-pointer"
                >
                  {t('click here')}
                </span>
                {t(' to request the new spot')}.
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 px-4 py-2 my-2 bg-bg_details border rounded-md">
          <div className="flex ml-px">
            <Checkbox
              id="isPhotographer"
              label={t('This is a private album')}
              value={isPrivateAlbum}
              onChange={handleChangePrivateAlbum}
              fontColor={'lumepic-light_black'}
              fontSize="1rem"
              fontWeight="400"
            />
          </div>
          {isPrivateAlbum && (
            <div className="flex flex-col gap-4 mb-1">
              <span className="text-xs font-light">{t('private_album_message')}</span>
              <TextField
                fullWidth
                variant="outlined"
                id="password"
                name="password"
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                style={{ backgroundColor: '#fff' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faLock} className="text-lumepic-grey" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEye} className="text-base" />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} className="text-base" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </div>

        <div className={`flex ${isNewAlbum ? 'justify-between' : 'justify-end'} mt-3`}>
          {isNewAlbum && (
            <div className="hidden sm:block">
              <Breadcrumb completedSteps={1} totalSteps={5} />
            </div>
          )}
          <Button
            btnType="PrimaryAction"
            isLoading={store.isLoading}
            onClick={formik.handleSubmit}
            extraStyle="w-full sm:w-min"
            type={'button'}
          >
            <div className="flex items-center justify-center gap-2">
              {isNewAlbum ? (
                <>
                  <span>{t('Next')}</span>
                  <FontAwesomeIcon icon={faArrowRight} className="text-lg" />
                </>
              ) : (
                <span>{t('Save')}</span>
              )}
            </div>
          </Button>
        </div>
      </div>
      {showNewActivityModal && (
        <RequestActivityModal
          closeModal={() => setShowNewActivityModal(false)}
          handleFinish={() => {
            setShowNewActivityModal(false)
            activityStore.fetchActivities()
            formik.setFieldValue('activityId', activityStore.requestedActivity.id)
          }}
        />
      )}
      {showNewLocationModal && (
        <RequestLocationModal
          closeModal={() => setShowNewLocationModal(false)}
          handleFinish={(newLocation) => {
            setShowNewLocationModal(false)
            setCountryCode(newLocation.countryCode)
            locationStore.fetchLocations(newLocation.countryCode).then((result) => {
              setAvailableLocations(result)
            })
            formik.setFieldValue('locationId', locationStore.requestedLocation.id)
          }}
        />
      )}
    </div>
  )
}

export default observer(AlbumForm)
